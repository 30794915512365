<template>
  <div class="backlink__container" :class="{ backlink__container_service: service }">
    <router-link :to="service_backlink || backlink" class="backlink icon-arrow">
      <span class="backlink__text">{{ 'back' | localize }}</span>
    </router-link>

    <div class="steps desktop" v-if="service">
      <div class="steps__title">{{ getTitle(service) | localize }}</div>
      <div class="step__container">
        <div class="step__num">1</div>

        <div class="step__wrapper">
          <router-link :to="step.url" class="step__point step__point_active" :class="{ step__point_current: step.url == $router.currentRoute.path }" v-for="(step, k) in step_links" :key="k">
            <div class="step__number"><div class="step__number_text">{{ k + 1 }}</div></div>
            <div class="step__title">{{ step.title | localize }}</div>
          </router-link>
        </div>

        <div class="step__point step__point_disabled" v-for="(step, k2) in calcSteps()" :key="(k2 + 100)">
          <div class="step__number"></div>
        </div>

        <div class="step__num">{{ steps }}</div>
      </div>
    </div>
    <div class="steps mobile" v-if="service && step_links[currentStep]">
      <div class="steps__title">{{ getTitle(service) | localize }}</div>
      <div class="step__num">{{ currentStep + 1 }}{{'\xa0'}}/{{'\xa0'}}{{ steps }}</div>
      <div class="steps__subtitle">{{ (step_links.length > 0 ? step_links[currentStep].title : 'empty') | localize }}</div>
    </div>

    <router-link :to="nextlink" class="nextlink nextlink_active icon-arrow" v-if="service && nextlink">
      <span class="backlink__text">{{ 'next' | localize }}</span>
    </router-link>
    <div v-else-if="service" class="nextlink icon-arrow">
      <span class="backlink__text">{{ 'next' | localize }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Backlink',
  props: {
    backlink: { type: [String, Object], default: '/' },
    service: { type: String, default: null },
    title: { type: String, default: 'information' },
    firstStep: { type: Boolean, default: false }
  },
  data: () => ({
    step_links: [],
    steps: null,
    currentStep: null,
    nextlink: null,
    service_backlink: null
  }),
  computed: { loaded_steps () { return this.$store.state.steps }},
  watch: { 
    service: function() { this.loadSteps() },
    loaded_steps: function() { this.loadSteps(true) }
  },
  created () { this.loadSteps() },
  methods: {
    loadSteps(saveStep = false) {
      if (this.service) {
        const userId = this.$store.state.user.id || 0
        const steps = JSON.parse(JSON.stringify(this.$store.state.steps || {}))

        // Сохранение линии действий:
        // - на первом шаге (firstStep)
        // - после загрузки из бд (saveStep) и поиска в массиве
        if ((this.firstStep || saveStep || steps[this.service].length > 0) && !steps[this.service].find(s => s.url == this.$router.currentRoute.path)) {
          steps[this.service].push({ url: this.$router.currentRoute.path, title: this.title })
          this.axios.put('user_params', {user_id: userId, steps: JSON.stringify(steps), service: this.service}).then(() => {
            this.$store.commit('setSteps', steps)
            this.$store.commit('setService', this.service)
          })
        }

        this.step_links = steps[this.service]
        this.steps = this.$store.state.all_steps[this.service]
        if (this.steps < this.step_links.length) this.steps = this.step_links.length

        // Корректировка кол-ва шагов с учетом пропущенных
        if (this.$store.state.missed_steps[this.service].length > 0) {
          this.steps = this.steps - this.$store.state.missed_steps[this.service].length
        }

        this.currentStep = this.step_links.findIndex(s => s.url == this.$router.currentRoute.path)
        if (this.step_links[this.currentStep + 1]) {
          this.nextlink = this.step_links[this.currentStep + 1].url
        }
        if (this.step_links[this.currentStep - 1]) {
          this.service_backlink = this.step_links[this.currentStep - 1].url
        }
      }
    },
    calcSteps() {
      const currentSteps = parseInt(this.steps - this.step_links.length)
      return currentSteps >= 0 ? currentSteps : 0
    },
    getTitle (service) {
      let title = ''
      switch (service) {
        case 'mortgage': title = 'calculate_mortgage'; break
        case 'refMortgage': title = 'refinance_mortgage'; break
        case 'credit': title = 'credit'; break
        case 'refCredit': title = 'refinance_credit'; break
      }
      return title
    }
  }
}
</script>
