<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="detail" service="refMortgage" backlink="/refinance-mortgage/reason" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "rm_s4p15_title" | localize }}</p>
          <div class="service__container">

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" class="service service_mortgage service_mortgage_1" @click="saveAndGo(1)">
                  <div class="service__title">
                    {{ "rm_s4p15_text_1" | localize }}
                  </div>
                </button>
              </template>
              <span>{{ "refmortgage_increase" | localize }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" class="service service_mortgage service_mortgage_2" @click="saveAndGo(0)">
                  <div class="service__title">
                    {{ "rm_s4p15_text_2" | localize }}
                  </div>
                </button>
              </template>
              <span>{{ "refmortgage_decrease" | localize }}</span>
            </v-tooltip>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Refinance_mortgage_type',
  components: { Backlink },
  mixins: [commonMixin],
  methods: {
    saveAndGo (value) {
      const refMortgage = this.$cookies.get('refMortgage') || {}
      refMortgage.type = value

      this.$cookies.set('refMortgage', refMortgage)

      // Сброс следующих шагов линии действий
      this.dropSteps('refMortgage', this.$router.currentRoute.path, '/refinance-mortgage/increase')

      // Переход в зависимости от выбора
      if (value === 1) {
        this.$router.push('/refinance-mortgage/increase')
      } else {
        this.addMissedSteps('refMortgage', ['/refinance-mortgage/increase'])
        this.$router.push('/refinance-mortgage/decrease')
      }
    }
  }
}
</script>
